import { Link } from "react-router-dom";

const About = () => {
  return (  
    <div className="all-list-container">
      <h1>Words Matter</h1>
      <div className="blurb">
        Critics of the Prophet Joseph Smith who claim him the author of a fictional book with imagined characters would find it difficult to dispute what you are about to discover.
        <br/><br/>
        A thorough analysis of speech patterns and words used shows that each voice you hear in the Book of Mormon is unique. Each person has his or her own viewpoint of the gospel, owing to his or her unique experiences, way of thinking, and way of speaking.
        <br/><br/>
        What are the odds that a 23-year-old with little formal education could invent and keep straight all of these unique differences? The chances are very low. If, on the other hand, he were to translate an ancient record (as he claimed), he would not need to keep them straight. They would speak for themselves, as they do in the Book of Mormon.
      </div>
      <div className="article subtitle">Contributions of the Book of Mormon Explorer</div>
      <div className="blurb">
        The Book of Mormon Explorer makes two powerful contributions to Book of Mormon study: cameos and data-visualization tools.
        <br/><br/>
    <b>Cameos and Stylometry</b><br/>
        Stylometry is the study of the writing styles of different writers, often in order to find out who wrote a particular text. <a href="https://scripturecentral.org/knowhy/what-can-stylometry-tell-us-about-book-of-mormon-authorship">Multiple stylometry studies</a> have been conducted on the Book of Mormon to try to determine who the author(s) are. This website is based on the <a href="https://scholarsarchive.byu.edu/jbms/vol21/iss1/4/">Fields study</a>. 
        <br/><br/>
<a href="https://youtu.be/SmygbHqy6gc?si=JoIzJ3g_cVqxV2dP" target='_blank'> Watch this 12-minute YouTube video</a> to get the big picture. Dr. Paul J. Fields is interviewed by Scripture Central about the data visualization of function words and content words, as they are used by various "speakers" (that is, authors) in the Book of Mormon. Dr. Fields is the expert in stylometrics who has guided the years of work that resulted in this website. <a href="https://youtu.be/mujxlX0QVm4?si=eEDWWrFUwccbRrB9" target='_blank'>Click here</a> to view "Book of Mormon Voices," a YouTube video of an hour-long presentation by Dr. Fields.
<br/><br/>
      This site contains articles, called cameos, that use stylometry to investigate 37 speakers, in terms of what they say and how they say it. Many speakers use words or phrases that are unique to them and appear nowhere else in the Book of Mormon. The cameos also present concepts that are discussed by multiple speakers, and examine how some speakers were influenced by the words of speakers in the past.
        <br/><br/>
    <b>Data-Visualization Tools</b><br/>
        The Book of Mormon Explorer also presents a toolbox of data-visualization tools.
        <br/><br/>
        The <a href='/toolbox/timeline'>Timeline</a> shows when the selected speaker appeared in the history of the Book of Mormon. It also presents significant events that occurred in the life of that speaker and allows the speaker's timeline to be compared with those of other speakers. 
        <br/><br/>
        The <a href='/toolbox/word-bubbles'>Word Bubbles</a> show the exact words used by the selected speaker, as well as the number of words they spoke and where they ranked in terms of their contribution to the Book of Mormon.
        <br/><br/>
        The <a href='/toolbox/connections'>Connections</a> diagram shows how speakers are associated with each other. They might be family members, religious or military associates, antagonists or enemies. The diagram also shows communications from God, which might be direct, in visions, or through angels.
        <br/><br/>
        In the <a href='/toolbox/network-diagram'>Network Diagram</a>, each node represents about 1000 words from a single speaker. The nodes are colored by speaker and grouped by text similarity. You can select a node and see what other nodes are related or select a speaker in the sidebar and have all of his nodes highlighted.
        <br/><br/>
        The <a href='/toolbox/BOM-semantic-map'>Book of Mormon Semantic Map</a> is a visualization of all Book of Mormon verses, plotted by their computed similarity of content.
        <br/><br/>
        As its name suggests, the <a href='/toolbox/similar-verse'>Similar Verse Finder</a> finds verses similar to the one you enter.
        <br/><br/>
        The <a href='/toolbox/social-network'>Social Network</a> shows conversations that occurred in the Book of Mormon, i.e., who spoke to whom. The links show the reference for the conversation.
        <br/><br/>
        These contributions can help people to better understand the Book of Mormon.
      </div>
      <div className='copyright'>
        Website content copyright 2024 VisibleLanguage
      </div>
    </div>
  );
}
 
export default About;