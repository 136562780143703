const SocialNetwork = () => {
  let parameters = new URLSearchParams(window.location.search);
  let speaker = parameters.get("speaker")

  let instructionContent = ""
  if(window.innerWidth>500){
    instructionContent = (
      <div className="blurb">
        Click and drag to rotate the network, or use the mouse wheel to zoom. 
        <br/>Click a node to highlight it and its connections, and to see its information in the Speaker Information tab. 
        <br/>Click a connection between two people to see a brief description of the interaction between them.
        <br/>Click the background without dragging to return the graph to normal.
        <br/>Use the buttons in the bottom-right corner to specify a node, then click "Go!" and the selected node will appear in the center of the screen.
        <br/>Check or uncheck legend items in the legend tab to toggle their display.
      </div>)
  }else{
    instructionContent = (
      <div className="blurb">
          Drag to rotate the network, or pinch to zoom. Drag with two fingers to pan.
          <br/>Tap any node to highlight its connections, or tap the background without dragging to return the graph to normal.
          <br/>Use the buttons in the bottom-right corner to specify a node, then click "Go!" and the selected node will appear in the center of the screen.
          <br/>Check or uncheck legend items in the legend tab to toggle their display.
      </div>)
  }

  return ( 
    <div className="widget-container"> 
      <h1>Book of Mormon Social Network</h1>
      <div>
        <div className="subcameo-subheading">Explanation</div> 
        <div className="blurb">
            This diagram shows a social network of the Book of Mormon, i.e., who speaks to whom. Each sphere represents a speaker in the Book of Mormon.
            The speakers are connected by lines representing interactions. Larger spheres represent people with a higher number of recorded interactions.
            The speakers float in 3-D space.
            The connections ‘pull’ the speaker nodes into their positions. The result is a 3-D network grouped by social networks.
        </div>
        <br/>
        <div className="subcameo-subheading">Instructions</div> 
        {instructionContent}
      </div>
      <iframe src={"https://bomextest.org/Widgets/SocialNetwork/index.html?speaker=" + speaker} className="network-iframe" style={{height: "800px", width: "100%", border:"none"}} ></iframe>

      <div>
        <div className="subtitle">FAQ</div>
        <br/>
        
        <div className="analysis-subheading">What is this network diagram?</div>
        <div className="analysis">
          This diagram is an example of a consensus network. Consensus networks are a visually intuitive way to show the relative similarity between things that can be compared on several distinct axes. The concept of consensus networks was developed in phylogenetics, when researchers wanted a more robust way to compare organisms by several different aspects at once.
          <br/><br/>
          In our consensus networks, each node represents a text. Connections between the nodes indicate similarity between the nodes they connect. The strength of the connections (how hard they pull on their neighbors) is indicative of the level of similarity between the texts.
        </div>
        <br/>

        <div className="analysis-subheading">What do the spheres represent?</div>
        <div className="analysis">
        Each sphere (node) represents a character from the Book of Mormon.
        </div>
        <br/>

        <div className="analysis-subheading">What do the connections represent?</div>
        <div className="analysis">
          Each connection represents one or more interactions between two speakers. Click on any connection to see a scripture describing an interaction between the two speakers.
        </div>
        <br/>

        <div className="analysis-subheading">What determines the placement of the nodes?</div>
        <div className="analysis">
          This network is uses force-directed graphing. In this system, the nodes sit in 3-D space, and pull towards each other where there are connections between them. Then, a force is applied to push the network apart. In the resulting graph, the speakers fall into place based on who they communicate with in the Book of Mormon.
        </div>
      
      </div>
    </div>
  );
}
   
export default SocialNetwork;
  