import { Link } from "react-router-dom";
const BOMSemanticMap = () => {
    return ( 
      <div className="widget-container"> 
        <h1>Semantic Map of the Book of Mormon</h1>
        <div>
          <div className="subcameo-subheading">Explanation</div> 
          <div className="blurb">
            This application visualizes semantic embeddings of verses derived using AI. Semantic embeddings are, simply put, a way of measuring whether two verses are talking about the same thing.
            <br/><br/>
            In this visualization, each dot is a verse from the Book of Mormon, color-coded by which book it appears in, and plotted based on its semantic similarity to the other verses. The further a dot is from the center, the more semantically unique that verse is, relative to all verses. Conversely, the closer two dots are, the more semantically similar they are. However, two dots that are moderately far apart are not necessarily more similar than two dots that are very far apart.
            <br/><br/>
            The semantic map shows recurring themes in the form of clusters of verses. A cluster of semantically similar verses, all in the same color, is potentially demonstrative of a speaker's unique voice. Thus, using the data presented here, it is possible to quantitatively differentiate each speaker's voice in the Book of Mormon. This is important because even the best authors can't artificially reproduce these sorts of semantic variation. For example, Jane Austen's characters - though they feel diverse when reading - do not exhibit semantic variation as great as that found among the speakers of Book of Mormon. Stylometric study, such as you can see in this semantic map, shows that the speakers in the Book of Mormon were probably not created by Joseph Smith. 
            <br/><br/>
      <a href="https://youtu.be/SmygbHqy6gc?si=JoIzJ3g_cVqxV2dP&start=8m13s" target='_blank'>Click here</a> to see a YouTube video of an explanation of the semantic map by Dr. Paul J. Fields.
          </div> 
          <br/><br/>
          <div className="subcameo-subheading">Example</div>
          <div className="blurb">
            The chapter Jacob 5 is a unique one in the Book of Mormon. Jacob quotes an otherwise unknown Israelite prophet, Zenos, in an extensive and continuous metaphor. The unique style and subject matter of Zenos are made evident by the cluster of yellow dots at the bottom and center of the semantic map. These dots belong to Jacob 5, and show a notable unity of content between the verses in that chapter. Other verses appearing in this cluster also tend to come from Old World speakers, such as Nephi/Lehi (in 1 Nephi), Isaiah (in 2 Nephi) and Jesus (in 3 Nephi). This easily observable data cluster demonstrates the employment of fruit imagery metaphors to be more characteristic of Old World Israelites than of the many post-migration backgrounds in the Book of Mormon, reflecting details in the cultures of the producing speakers.
          </div>
        </div>

        <iframe src="https://bomextest.org/Widgets/SemanticMaps/BOMSemanticMap/index.html" style={{height: "80vh", width: "80vw", border:"none"}} ></iframe>

        <div>
          <div className="subtitle">FAQ</div>
          <br/>

          <div className="analysis-subheading">What is semantic similarity?</div>
          <div className="analysis">
            Semantic similarity, in the context of computational linguistics, is a method of defining the similarity between two linguistic units (which may be words, phrases, or documents - here, verses). Each verse is "semantically embedded" - converted from an arbitrary string of tokens (a list of letters and words) into a high-dimensional vector (a list of numbers). This creation of embeddings from tokens in the secret sauce of computers understanding human language, and incorporates the latest advances in AI and statistics. The key thing is that, in the end, numerically similar vectors will reflect semantically similar verses.
            <br/><br/>
            Then, for ease of visualization, the vectors can be reduced from a list of many numbers to a list of 2 or 3 numbers - which can then be used as coordinates on a 2D or 3D map. This process of dimensionality reduction (we used one called UMAP) preserves the closeness of the vectors.
          </div>
        </div>
      </div>
     );
  }
   
  export default BOMSemanticMap;
  
