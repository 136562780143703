const Timeline = () => {
  let parameters = new URLSearchParams(window.location.search);

  let speaker = ""
  if (parameters.get("speaker")) {
    speaker = parameters.get("speaker")
  }
  return ( 
    <div className="widget-container"> 
      <h1>Timeline</h1>
      <div className="analysis">
              This chart displays a timeline of events in the Book of Mormon. Select a speaker to see where they appear on the timeline and view events from their life. Click on a name in the scrollable horizontal list to compare the lifetime of that person with that of the selected speaker. Click "View significant events" to see a list of events that occurred in the speaker's lifetime.
      </div>
      <iframe src={"https://bomextest.org/Widgets/Timeline/index.html?speaker=" + speaker} style={{height: "1000px", width: "100%", border:"none"}} ></iframe>
      <br/>
    </div>
   );
}
 
export default Timeline;
