import { Link } from "react-router-dom";
const WordBubbles = () => {
  let parameters = new URLSearchParams(window.location.search);
  let speaker = parameters.get("speaker")
  let word = parameters.get("word")

  return ( 
      <div className="widget-container"> 
        <h1>Word Bubbles</h1>
        <div className="analysis">
        This bubble chart displays language used by the selected Book of Mormon speaker. Each bubble shows a word, along with the number of times it is used by that speaker. If you click on a bubble, it will show a verse that includes that word.
    <br/><br/>
    You can filter by content words or function words, limit how many words are displayed, and select words that are unique to the speaker. 
		    <br/><br/>   
        <b>Please note that some scriptures have been shortened to fit in the bubble.</b>
        </div>
        <iframe src={"https://bomextest.org/Widgets/Bubbles/index.html?speaker=" + speaker} className="bubbles-iframe" style={{height: "180vh", width: "80vw", border:"none"}} ></iframe>
      </div>  
     );
}
 
export default WordBubbles;
