const NetworkDiagram = () => {
  let parameters = new URLSearchParams(window.location.search);
  let speaker = parameters.get("speaker")

  let instructionContent = ""
  if(window.innerWidth>500){
    instructionContent = (
      <div className="analysis">
        Click and drag to rotate the network, or use the mouse wheel to zoom. 
        <br/>Left-click a node to highlight it and its connections, and to see its information in the Speaker Information tab. Right-click a node to select it and see its information without changing the highlight state of the graph.
        <br/>Click the background without dragging to return the graph to normal.
        <br/>Use the buttons in the bottom-right corner to specify a node, then click "Go!" and the selected node will appear in the center of the screen.
        <br/>Check or uncheck legend items in the legend tab to toggle their display.
      </div>)
  }else{
    instructionContent = (
      <div className="analysis">
          Drag to rotate the network, or pinch to zoom. Drag with two fingers to pan.
          <br/>Tap any node to highlight its connections, or tap the background without dragging to return the graph to normal.
          <br/>Use the buttons in the bottom-right corner to specify a node, then click "Go!" and the selected node will appear in the center of the screen.
          <br/>Check or uncheck legend items in the legend tab to toggle their display.
      </div>)
  }

  return ( 
    <div className="widget-container"> 
      <h1>Network Diagram</h1>
      <div>
        <div className="subcameo-subheading">Explanation</div> 
        <div className="blurb">
              This network diagram represents the Book of Mormon. Each node represents about 1000 words by a given speaker. These chunks of text are called documents.
              The documents are connected by lines that indicate similarity. To improve performance, you can make these connections invisible by using the button at the bottom of the screen.
              The nodes float in 3-D space, with similar nodes close together.
              The connections ‘pull’ the documents into their positions. The result is a 3-D network grouped by text similarity.
        </div>
        <br/>
        <div className="subcameo-subheading">Instructions</div> 
        {instructionContent}
      </div>
      <iframe src={"https://bomextest.org/Widgets/SpeakersNetwork/index.html?speaker=" + speaker} className="network-iframe" style={{height: "800px", width: "100%", border:"none"}} ></iframe>
      <div>
        <div className="subtitle">FAQ</div>
        <br/>

        <div className="analysis-subheading">What is this network diagram?</div>
        <div className="analysis">
          This diagram is an example of a consensus network. Consensus networks are a visually intuitive way to show the relative similarity between things that can be compared on several distinct axes. The concept of consensus networks was developed in phylogenetics, when researchers wanted a more robust way to compare organisms by several different aspects at once.
          <br/><br/>
          In our consensus networks, each node represents a text. Connections between the nodes indicate similarity between the nodes they connect. The strength of the connections (how hard they pull on their neighbors) is indicative of the level of similarity between the texts.
        </div>
        <br/>
        
        <div className="analysis-subheading">What do the spheres represent?</div>
        <div className="analysis">
          Each sphere (node) represents about 1000 words from a given speaker. These words have been attributed to that speaker. The words fall between the start reference and end reference on the 'Speaker Information' tab.
        </div>
        <br/>
        
        <div className="analysis-subheading">What do the connections represent?</div>
        <div className="analysis">
          Each connection is indicative of similarity between the two nodes it connects.
        </div>
        <br/>
        
        <div className="analysis-subheading">Why do some nodes have more connections than others?</div>
        <div className="analysis">
          When the networks are created, each node 'reaches out' to the three nodes to which it is most similar. This process is repeated many times, using different measures to compare the documents. Because of this, a node can choose distinct neighbors in different stages of the network-creation process. In this way, some nodes choose several distinct neighbors, whereas others only choose three.
          <br/><br/>
          Additionally, although each node is guaranteed to reach out to at least three others, there is no guarantee that any given node will be chosen by another. This can also lead to fewer connections.
        </div>
        <br/>
        
        <div className="analysis-subheading">What determines the placement of the nodes?</div>
        <div className="analysis">
          This network uses force-directed graphing. In this system, the nodes sit in 3-D space, and pull towards each other where there are connections between them. Stronger connections between documents pull their nodes closer together. Then, a force is applied to push the network apart. In the resulting graph, the nodes fall into place, based on their relative similarity to other documents in the graph.
        </div>
        <br/>
        
        <div className="analysis-subheading">What is meant by the terms "incoming" and "outgoing" connections?</div>
        <div className="analysis">
          During network creation, each node 'reaches out' to at least three other nodes. Each of these connections are outgoing connections. If a node is chosen by another document, that connection is an incoming connection.
        </div>
        <br/>
        
        <div className="analysis-subheading">How are the consensus networks created?</div>
        <div className="analysis">
          Consensus networks are created by comparing each text to each other text using several different methods of comparison.
          <br/><br/>
          Each text is compared to each other document based on a textual feature. Then, the text ‘reaches out’ to its three nearest neighbors. This process is repeated with several textual features, and similarity is measured several different ways for each textual feature. 
          Each time a text reaches out to one its neighbors, a connection is made between those two nodes.
          <br/><br/>
          All these connections are layered on top of one another, resulting in a robust web of connections between texts. The ‘consensus’ portion of the name refers to this layering of connections, as the network provides a consensus of where strong inter-textual connections lie.
        </div>

      </div>
    </div>
  );
}
 
export default NetworkDiagram;