import 'react-toastify/dist/ReactToastify.css';

const Feedback = () => {
    return(
        <div className="all-list-container" style={{height:"70vh"}}>
            <h1>Contact Us</h1>            
            <div className='blurb'>
                We would love to hear from you. If you have any questions or comments, please send us an email at <i>explorer@visiblelanguage.info</i> . Thank you for helping us to improve Book of Mormon Explorer.
                <br/><br/>
                We suggest that you add <i>explorer@visiblelanguage.info</i> to your Contacts list to avoid having our response end up in your spam filter.
            </div>
        </div>
    )
}

export default Feedback;