const SimilarVerse = () => {
  let parameters = new URLSearchParams(window.location.search);

  let reference = ""
  if (parameters.get("reference")) {
    reference = parameters.get("reference")
  }

  return ( 
    <div className="widget-container"> 
      <h1>Similar Verse Finder</h1>
      <div>
        <div className="subcameo-subheading">Instructions</div> 
        <div className="blurb">This application lets you search the Standard Works for similar verses. Type a verse reference into the search bar, using the format Book Chapter:Verse (e.g., 1 Nephi 1:1 or Alma 62:28). The widget uses computational semantics to determine verses with similar content by measuring their semantic similarity. Semantic similarity is superior to a simple keyword lookup because it is robust to (or resistant to) synonyms. This means that two verses, one that talks about trees, and one that talks about vines, would be seen as similar even if they use different words - because "trees" and "vines" fall together into a semantic domain. This makes the intertextual connections shown here potentially more deep and numerous than the ones that could be found using traditional footnotes, or study aids like a thesaurus.</div> 
      </div>
      
      <iframe src={"https://bomextest.org/Widgets/SimilarVerse/index.html?reference=" + reference} className="similar-verse-iframe" style={{height: "800px", width: "100%", border:"none"}} ></iframe>
    </div>
    );
  }
   
  export default SimilarVerse;
  
