import { Link } from "react-router-dom";
import title_data from "./cameo_article_metadata.json";
import ImageLoader from "./ImageLoader";


const ArticlesAll = ( ) => {
    // list of objects w "title" (for key and display) and "link" (for url param and later cameo map)
    const speakers = title_data.cameo_title_link_img.speakers
    const concepts = title_data.cameo_title_link_img.concepts
    const influences = title_data.cameo_title_link_img.influences

    const descriptions = {
        "speakers": "These articles, called cameos, use stylometry to investigate 37 speakers from the Book of Mormon, in terms of what they say and how they say it. Many speakers use words or phrases that are unique to them and appear nowhere else in the Book of Mormon.",
        "concepts": "These cameos discuss concepts that appear in the Book of Mormon.",
        "influences": "These cameos discuss the influence of an earlier speaker on the writings of a later speaker."
    }
    

    function listCameos(data, cameoType) {
        return (
            <div className="all-list-container-sub">
                <h2 className="article subtitle">{capitalizeFirstLetter(cameoType)}</h2>
                <br/>
                <div className="blurb">{descriptions[cameoType]}</div>
                <div className="articles-list-all" style={{textAlign: 'center'}}>
                    {
                        data.map((article) => (
                            <Link key={article.title} className="articles-list-all-card" to={ cameoType + '/' + article.link }>
                                <img className="speakerall-img" src={ImageLoader(article.img)} alt={article.img} />
                                <h4> { article.title }</h4>
                            </Link>
                        ))
                    }
                </div>
            </div>
        )
    }

    // from the internet :)
    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }

    // to top button function
    function scrollTop () {
        window.scrollTo({
        top: 0,
        behavior: "smooth"
    });}

    // the button only appears after scrolling down
    const onScroll = () => {
        const scrollTopButton = document.getElementById("scrollTopButton");
        if (scrollTopButton) {
            if (document.documentElement.scrollTop > 1000) {
                scrollTopButton.classList.add("show");
            } else {
                scrollTopButton.classList.remove("show");
            }
        }
    }
    window.addEventListener('scroll', onScroll)

    return (
        <div>
            <div className="all-list-container">
                <h1>All Cameos</h1>
                <div className="blurb">
                    Stylometry is the study of the writing styles of different writers, often in order to find out who wrote a particular text. <a href="https://scripturecentral.org/knowhy/what-can-stylometry-tell-us-about-book-of-mormon-authorship">Multiple stylometry studies</a> have been conducted on the Book of Mormon to try to determine who the author(s) are. This website is based on the <a href="https://scholarsarchive.byu.edu/jbms/vol21/iss1/4/">Fields study</a>. <a href="https://youtu.be/SmygbHqy6gc?si=JoIzJ3g_cVqxV2dP" target='_blank'>Click here</a> to view a 12-minute YouTube video of an interview with Dr. Paul J. Fields as he discusses stylometry. <a href="https://youtu.be/mujxlX0QVm4?si=eEDWWrFUwccbRrB9" target='_blank'>Click here</a> to view "Book of Mormon Voices," a YouTube video of an hour-long presentation by Dr. Fields. Below is a brief video walkthrough of the structure of our cameos.
                </div>
                <iframe className='youtube-iframe' width="560" height="315" src="https://www.youtube.com/embed/uBGLi87iuGs?si=fQlWwDzZtxSB8P_W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                {listCameos(speakers, 'speakers')}
                {listCameos(concepts, 'concepts')}
                {listCameos(influences, 'influences')}
            </div>
            <div id="scrollTopButton" className="scroll-top-button" onClick={scrollTop}>to top</div>
        </div>
)

}

export default ArticlesAll
