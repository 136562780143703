const Connections = () => {
  let parameters = new URLSearchParams(window.location.search);
  let speaker = parameters.get("speaker")

  return ( 
    <div className="widget-container"> 
      <h1>Connections</h1>
      <div className="blurb">
        The Connections diagram shows how the selected speaker is associated with other people. They might be family members (brown), religious or military associates (green), or antagonists or enemies (purple). The diagram also shows communications from God (yellow), which might be direct, in visions, or through angels.
        <br/><br/>
        Check and uncheck the connection types to highlight on the graph. Select from the dropdown list the speaker whose connections you wish to focus on, or click on one of the icons in the graph directly. Each connection is explained in the box below labeled "social connections."
      </div>
      <iframe src={"https://bomextest.org/Widgets/Connections/index.html?speaker=" + speaker} className="connections-iframe" style={{height: "1000px", width: "100%", border:"none", paddingTop:'1em'}} ></iframe>
    </div>
   );
}

export default Connections;
