import { useParams } from "react-router-dom";
import NotFound from "./NotFound";
import Data_Manager from "./Data_Manager";
import blurb_data from "./cameo_blurb_breakdown.json";
import meta_data from "./cameo_article_metadata.json";
import ImageLoader from "./ImageLoader";

const Article = () => {
    const { cameo_type , article_tag } = useParams()  
    
    const title_link_img = meta_data.cameo_title_link_img[cameo_type]
    const article = title_link_img.filter(obj => {
        return obj.link === article_tag
    })[0]


    // For every cameo, we have:
    // [cameo]-description: this is the first couple of paragraphs
    // [cameo]-fact_1 or fact_2: this is the first heading, previously a dropdown, to be placed before the analysis.
    //                          NB: for some this was blank (or "Read More...", which was counted as blank)
    // [cameo]: what was previously the analysis, the only thing in Data_Manager (previously Analysis_Loader)
    const which_blurbs = blurb_data.data[article_tag]

    // from the internet :)
    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }

    // bug fix: prevent article from loading in a partially scrolled position
    window.scrollTo({top:0,behavior:'auto'})

    // function for button uses 'smooth'
    function scrollTop () {
            window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    // the button only appears after scrolling down
    const onScroll = () => {
        const scrollTopButton = document.getElementById("scrollTopButton");
        if (scrollTopButton) {
            if (document.documentElement.scrollTop > 1000) {
                scrollTopButton.classList.add("show");
            } else {
                scrollTopButton.classList.remove("show");
            }
        }
    }
    window.addEventListener('scroll', onScroll)

    return (
        <div>
            <div className="article-container">
                <div className="front-matter">
                    <div className="metadata">
                        <div className="metadatum1">Category: {capitalizeFirstLetter(cameo_type)}</div>
                        <div className="metadatum2" style={{display: 'none'}}>Cameo code: {article_tag}</div>
                    </div>
                    <h1 className="article title">{article.title}</h1>
                    <img className="speaker-img" src={ImageLoader(article.img)}></img>
                </div>
                <div className="article-body">
                    <div className="abstract-container">
                        <div className="abstract-title">Abstract</div>
                        <Data_Manager id={article.link + "-abstract"}/>
                    </div>
                    {
                    which_blurbs.map((blurb_tag) => (
                        <Data_Manager key={blurb_tag} id={blurb_tag}/>
                    ))
                    }
                </div>
            </div>
            <div id="scrollTopButton" className="scroll-top-button" onClick={scrollTop}>to top</div>
        </div>
    )
}

export default Article
