const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-links">
                <a href="/">Home</a>
                <a href="/toolbox">Toolbox</a>
                <a href="/cameos">Cameos</a>
                <a href="/about">About</a>
                <a href="https://scripturecentral.org/">Back to Scripture Central</a>
            </div>
            <div className='copyright'>Website content copyright 2024 VisibleLanguage</div>            
        </footer>
    )
}

export default Footer