import { Link } from "react-router-dom";

const NotFound = () => {
  return ( 
    <div className="all-list-container" style={{height: '70vh'}}>
      <h1>Sorry!</h1>
      <p className='subtitle'>
        That page cannot be found.
      </p>
      <a className='subtitle' href="/">Take me back to the Homepage</a>
      <br/>
    </div>
   );
}
 
export default NotFound;